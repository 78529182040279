<template>
  <div
    v-if="data.fields"
  >
    <div class="mongodb-charts-wrapper" v-if="chartsList">
      <div
        :id="`chart-${chart.id}`"
        v-for="chart in chartsList"
        class="chart-block"
        :class="{'large': chart.large}"
      ></div>
    </div>
  </div>
</template>

<script>
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'

export default {
  name: 'Banner',

  transition: 'page',

  props: [ 'data' ],

  computed: {
    chartsList () {
      if (this.data.fields.mongoDBCharts) {
        var list = this.data.fields.mongoDBCharts.charts
        return list
      }
      return []
    }
  },

  methods: {
  },

  mounted () {
    setTimeout(() => {
      const sdk = new ChartsEmbedSDK({
        baseUrl: this.data.fields.mongoDBCharts.url
      })
      this.chartsList.forEach((key, index) => {
        const chart = sdk.createChart({ chartId: key.id, showAttribution: false })
        chart.render(document.getElementById(`chart-${key.id}`))
      })      
    }, 1000)    
  }
}
</script>

<style scoped lang="scss">
.mongodb-charts-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  border-top: 1px solid $gray-light;
  padding-top: 1em;
  justify-content: space-between;
  align-items: flex-start;
}
.chart-block {
  width: 100%;
  height: 400px;
  border: 1px solid $gray-light;
  margin-bottom: 1em;
  @include breakpoint($tablet) {
    width: calc((100vw - 4em) / 3);
    height: 500px;
  }
  &.large {
    @include breakpoint($tablet) {
      width: calc((100vw - 4em) / 3 * 2);
    }
  }
  @include breakpoint($fullhd) {
    width: calc((100vw - 5em) / 4);
    height: 500px;
  }
  &.large {
    @include breakpoint($fullhd) {
      width: calc((100vw - 5em) / 4 * 2);
    }
  }
}
</style>